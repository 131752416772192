import {navigate} from 'gatsby';
import {useEffect} from 'react';
import {Layout} from '../../components/layout';
import useChannelTabsData, {ChannelTabsVariant} from '../../hooks/useChannelTabsData';

const PlaylistsPage = () => {
    const channelTabsData = useChannelTabsData(ChannelTabsVariant.playlists);

    useEffect(() => {
        if (!channelTabsData || channelTabsData.length === 0) {
            return;
        }

        navigate(channelTabsData[0].to);
    }, [channelTabsData]);

    return (
        <>
            <Layout
                title="Playlist manager"
                scope="playlists"
            />
        </>

    );
};

export default PlaylistsPage;
